<div class="header-container">
  <app-header></app-header>
</div>
<div class="resultado-estudio">
  <div
    class="contenedor-resultado"
    [ngClass]="{ large: hasApprovalCertificate }"
  >
    <i
      class="fa fa-cog a-spin cargando-inicio"
      *ngIf="loading; else loaded"
    ></i>

    <ng-template #loaded>
      <div class="aprobado" *ngIf="canGoToSentToInformView && !isRepeatedRut">
        <h1>APROBACIÓN LEGAL</h1>
        <p>
          Las siguientes personas tienen pre-aprobación legal para abrir y
          operar productos en representación de la empresa
          <b>{{ study.businessName }}</b>, RUT <b>{{ study.rut }}</b>:
        </p>
        <ul class="pre-administradores">
          <li *ngFor="let administrator of study.administrators">
            {{ administrator.name }}, RUT {{ administrator.rut }}
          </li>
        </ul>

        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>

        <app-button
          [loading]="sendingToInform"
          [text]="'Crear Informe'"
          [valid]="!sendingToInform"
          (onClick)="enviar()"
        >
        </app-button>
      </div>

      <div class="reprobado" *ngIf="isNotLegalApproved">
        <app-pdf-viewer [pdf]="this.study.certificate"></app-pdf-viewer>
        <div class="dispatch_buttons" *ngIf="hasDispatch">
          <label
            for="checkRequestStudy"
            style="color: #777; margin-bottom: 2rem; margin-top: 0.5rem"
          >
            <input
              type="checkbox"
              id="checkRequestStudy"
              [checked]="checkRequestStudy"
              (change)="handleCheckRequestStudy()"
              [disabled]="dispatching"
            />
            Informar esta sociedad vía revisión manual
          </label>
          <app-button
            [loading]="dispatching"
            [text]="'Enviar a informar'"
            [valid]="checkRequestStudy && !dispatching"
            (onClick)="requestStudy()"
          >
          </app-button>
          <div *ngIf="isSantander; then santanderFootnote; else footnote"></div>
          <ng-template #santanderFootnote>
            <span class="footnote">
              *En caso de confirmar, la solicitud será enviada a abogados
              externos.
            </span>
          </ng-template>
          <ng-template #footnote>
            <span class="footnote">
              *En caso de confirmar, la solicitud será enviada a abogados
              Legalbot.
            </span> 
          </ng-template>
        </div>
      </div>

      <div *ngIf="isRepeatedRut">
        <div class="forceDispatch" *ngIf="!active && hasDispatch">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    role="button"
                    type="button"
                    (click)="backToSearch()"
                  >
                    <i class="fas fa-chevron-left"></i>
                    Cancelar
                  </button>
                </div>
                <div class="col-md-3 ml-3">
                  <div class="row justify-content-end">
                    <i class="fas fa-exclamation-triangle fa-3x"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-10 mt-5 mb-3">
              <p class="text-center">
                El RUT <b>{{ resultServiceResponse.rut }}</b> ya fue envíado a
                estudiar previamente, con fecha
                <b>{{
                  resultServiceResponse.lastDispatchDate ||
                    resultServiceResponse.createdAt | date: "dd-MM-yyyy HH:mm"
                }}</b>
              </p>
              <p class="text-center">
                <label style="color: red">
                  En caso de necesitar subsanar algún reparo, favor dirigir el
                  correo al buzón Fiscalía subsana reparo.
                </label>
              </p>
            </div>
            <div class="col-md-10">
              <label for="checkRequestStudy" style="color: #777">
                <input
                  type="checkbox"
                  id="checkRequestStudy"
                  [checked]="checkRequestStudy"
                  (change)="handleCheckRequestStudy()"
                  [disabled]="dispatching"
                />
                Deseo solicitar este estudio independiente de cualquier otro
                estudio previamente informado.
              </label>
              <app-button
                [loading]="dispatching"
                [text]="'Solicitar estudio'"
                [valid]="checkRequestStudy && !dispatching"
                (onClick)="requestStudy(true)"
              >
              </app-button>
            </div>
          </div>
        </div>

        <div class="isActive" *ngIf="active && hasDispatch">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <i class="fas fa-exclamation-triangle fa-3x"></i>
            </div>
            <div class="col-md-10 mt-5 mb-4">
              <p class="text-justify">
                El rut <b>{{ resultServiceResponse.rut }}</b> ya fue requerido
                el
                <b>{{
                  resultServiceResponse.createdAt | date: "dd-MM-yyyy HH:mm"
                }}</b>
                y se encuentra en estudio, una vez finalizado se informará en
                los sistemas del banco.
              </p>
            </div>
            <div class="col-md-5 mx-auto">
              <button
                class="btn btn-primary btn-block"
                role="button"
                type="button"
                (click)="backToSearch()"
              >
                <i class="fas fa-chevron-left mr-3"></i>
                Solicitar otro estudio
              </button>
            </div>
          </div>
        </div>
        <div class="hasNotDispatch" *ngIf="!hasDispatch">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <i class="fas fa-exclamation-triangle fa-3x"></i>
            </div>
            <div class="col-md-10 mt-5 mb-4">
              <p
                class="text-justify"
                *ngIf="
                  resultServiceResponse.status === 2 ||
                    resultServiceResponse.status === 7;
                  else other_status
                "
              >
                El rut <b>{{ resultServiceResponse.rut }}</b> ya fue solicitado
                <label *ngIf="resultServiceResponse.createdAt">
                  con fecha
                  <b>{{
                    resultServiceResponse.createdAt | date: "dd-MM-yyyy HH:mm"
                  }}</b
                  >.
                </label>
                <br />
                y se encuentra en etapa de estudio, será informado prontamente
                en los sistemas de consulta de poderes del banco.
              </p>
              <ng-template #other_status>
                <p class="text-justify">
                  El rut <b>{{ resultServiceResponse.rut }}</b> ya fue informado
                  previamente. <br />Recomendamos revisar los sistemas de
                  consulta de poderes del banco.
                </p>
              </ng-template>
            </div>
            <div class="col-md-5 mx-auto">
              <button
                class="btn btn-primary btn-block"
                role="button"
                type="button"
                (click)="backToSearch()"
              >
                <i class="fas fa-chevron-left mr-3"></i>
                Solicitar otro estudio
              </button>
            </div>
          </div>
        </div>
      </div>

      <app-alert
        [alertType]="ALERT.DANGER"
        [alertMessage]="dispatchErrorMessage"
      >
      </app-alert>
      <ng-template #content>
        <app-modal
          [lawyerEmail]="lawyerEmail"
          [lawyerName]="lawyerName"
          [nationalId]="study.rut"
        ></app-modal>
      </ng-template>
    </ng-template>
  </div>
</div>
