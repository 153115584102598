import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-browser-alert",
  templateUrl: "./browser-alert.component.html",
  styleUrls: ["./browser-alert.component.scss"],
})
export class BrowserAlertComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  isBrowserSupported() {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("MSIE ") > 0) return false;
    if (userAgent.indexOf("Trident/") > 0) return false;
    return true;
  }
}
