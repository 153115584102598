import { Component, OnInit } from "@angular/core";

import { AuthSantanderService } from "src/app/services/auth-santander.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  showNav = false;
  showDropDownProfile = false;

  constructor(private auth: AuthSantanderService) {}

  ngOnInit() {}

  exit() {
    this.auth.exit();
  }

  toggleNavbar() {
    this.showNav = !this.showNav;
  }

  toggleProfileDropDown() {
    this.showDropDownProfile = !this.showDropDownProfile;
  }
}
