import { ElementRef, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ModalComponent } from "../components/modal/modal.component";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(
    private modalService: NgbModal,
    private router: Router
  ) {}

  open(content: ModalComponent | ElementRef): void {
    this.modalService.open(content, { size: "lg", centered: true, backdrop: false });
  }

  close(): void {
    this.modalService.dismissAll({
      dismissed: "close",
    });
    this.router.navigate(["busqueda"]);
  }
}
