import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthSantanderService } from "./auth-santander.service";

interface Response {
  ok: boolean;
  mensaje: string;
  data: any;
  auth: boolean;
  code?: number;
  message?: string;
}

interface ResponseList {
  count: number;
  next: any;
  previous: any;
  results: any;
}

export enum STATUSES {
  PRE_STUDIED = 1,
  SENT_TO_INFORM = 2,
  FINALIZED = 3,
  IN_STUDY = 7,
  DISPATCHED = 8,
  NOT_STUDYABLE = 9,
  DELIVERED = 10,
}

@Injectable({
  providedIn: "root",
})
export class StudiesService {
  /*
  ------------------------------------------------------------------------------------------
  GLOBALES
  ------------------------------------------------------------------------------------------
  */

  // url del servicio estudio
  urlService: string = environment.api_gateways.study;

  // mensaje de error del backend
  messageError = `Estamos presentando problemas de conexión.
     Inténtalo nuevamente en unos segundos, si el
     problema persiste comunícate a contacto@legalbot.cl`;
  message500Error = `El estudio solicitado presenta un problema, favor
     comunícate a contacto@legalbot.cl indicando el rut
     solicitado para poder ayudarte`;
  constructor(private http: HttpClient, private auth: AuthSantanderService) {}

  // función general de llamado get al servicio de estudios
  private async estudioGet(
    servicio: string,
    parametro: string = ""
  ): Promise<Response> {
    // crear la url a donde se llamará el GET utilizando las variables de entrada
    const url =
      this.urlService +
      (parametro === "" ? "" : parametro + "/") +
      (servicio === "" ? "" : servicio + "/");

    // llamar el servicio al endpoint con un GET
    try {
      const response = await this.http.get<Response>(url).toPromise();
      const data: Response = {
        ok: true,
        mensaje: "",
        data: response,
        auth: true,
      };
      return data;
    } catch {
      // error en llamado al endpoint
      const response: Response = {
        ok: false,
        mensaje: this.messageError,
        data: {},
        auth: true,
      };
      return response;
    }
  }

  // función general de llamado get al servicio de estudios
  private async estudioGetList(
    servicio: string,
    pageUrl = "",
    params: any = {}
  ): Promise<ResponseList> {
    // crear la url a donde se llamará el GET utilizando las variables de entrada
    const url =
      pageUrl || this.urlService + (servicio === "" ? "" : servicio + "/");
    // llamar el servicio al endpoint con un GET
    try {
      const response: ResponseList = await this.http
        .get<ResponseList>(url, {
          params,
        })
        .toPromise();
      return response;
    } catch {
      // error en llamado al endpoint
      const response: ResponseList = {
        count: 0,
        next: null,
        previous: null,
        results: {
          ok: false,
          mensaje: this.messageError,
          data: {},
          auth: true,
        },
      };
      return response;
    }
  }

  // TODO provisional, la meta es que todo los mensajes de error del back se devuelvan
  private handleErrorMessage(error) {
    const message = error && error.error && error.error.message;
    if (message && error.error.code)
      return message.charAt(0).toUpperCase() + message.slice(1);
    return this.messageError;
  }

  // función general de llamado post al servicio de estudios
  private async estudioPost(
    servicio: string,
    parametro: string = "",
    datosPost: any = {}
  ): Promise<Response> {
    // crear la url a donde se llamará el POST utilizando las variables de entrada
    const url =
      this.urlService +
      (parametro === "" ? "" : parametro + "/") +
      (servicio === "" ? "" : servicio + "/");
    // llamar el servicio al endpoint con un POST
    try {
      const response: Response = await this.http
        .post<Response>(url, datosPost)
        .toPromise();

      return {
        ok: true,
        ...response,
      };
    } catch (error) {
      // error en llamado al endpoint
      const errorMessage =
        error.status === 500
          ? this.message500Error
          : this.handleErrorMessage(error);

      const response: Response = {
        ok: false,
        mensaje: errorMessage,
        data: {},
        auth: true,
      };
      return response;
    }
  }

  private getStatusDisplayName(study: any) {
    if (study.status === STATUSES.IN_STUDY) {
      return "Revisión Manual - En Estudio";
    } else if (
      study.isApproved === true &&
      [STATUSES.FINALIZED, STATUSES.PRE_STUDIED].indexOf(study.status) > -1
    ) {
      return "Aprobación Legal Automática";
    } else if (study.status === STATUSES.SENT_TO_INFORM) {
      return "Aprobación Legal Automática - Informe en Curso";
    } else if (
      study.isApproved === null &&
      [STATUSES.FINALIZED, STATUSES.PRE_STUDIED].indexOf(study.status) > -1
    ) {
      return "Sin Aprobación Legal Automática";
    } else if (study.status === STATUSES.DELIVERED && study.isApproved) {
      return "Aprobación Legal";
    } else if (study.status === STATUSES.DELIVERED && !study.isApproved) {
      return "Rechazo Legal";
    } else if (study.status === STATUSES.DISPATCHED) {
      return "Revisión Manual - Abogado Externo";
    }
    return null;
  }

  public async studyDispatch(id: string, force: boolean): Promise<any> {
    const response: Response = await this.estudioPost(
      "dispatch",
      id,
      force ? { force } : {}
    );
    return response;
  }

  // llama la función iniciar/{id} del servicio de estudios en el backend
  public async estudioComenzar(
    rut: string,
    costCenter: number = null
  ): Promise<any> {
    const response: Response = await this.estudioPost("", "", {
      rut,
      costCenter,
    });

    if (response.code && response.message) {
      const message = this.handleErrorMessage({ error: response });
      return {
        ok: false,
        mensaje: message,
        data: {},
        auth: true,
      };
    }

    return response;
  }

  // obtener estudio guardado desde el servicio estudio/obtener-estudio
  public async obtenerEstudioGuardado(id: string): Promise<any> {
    const response: Response = await this.estudioGet(id);
    if (response.ok) {
      response.data.actuations = response.data.actuations.sort((a, b) => {
        const dateA = new Date(a.documentDate);
        const dateB = new Date(b.documentDate);
        return dateA.getTime() - dateB.getTime();
      });

      return response.data;
    }
    return null;
  }

  // marcar estudio para enviar a estudio manual
  public async marcarParaEstudioManual(id: string): Promise<any> {
    const response: Response = await this.estudioPost("send-to-inform", id, {});
    return response;
  }

  public async getCertificate(id: string): Promise<any> {
    const response: Response = await this.estudioPost("document", id, {});
    return response;
  }

  public canGoToResult(study: any) {
    if (
      study.status === STATUSES.FINALIZED ||
      study.status === STATUSES.SENT_TO_INFORM ||
      study.status === STATUSES.NOT_STUDYABLE ||
      (study.status === STATUSES.DELIVERED && study.isApproved !== null)
    ) {
      return true;
    }
    return false;
  }

  public canGoToLegalApprovalInform(study: any) {
    return (
      study.status === STATUSES.SENT_TO_INFORM ||
      (study.isApproved &&
        (study.status === STATUSES.FINALIZED ||
          study.status === STATUSES.DELIVERED))
    );
  }

  public canGoToRejectedInform(study: any) {
    return (
      study.isApproved === false &&
      (study.status === STATUSES.FINALIZED ||
        study.status === STATUSES.DELIVERED)
    );
  }

  public canGoToNotStudyableInform(study: any) {
    return study.status === STATUSES.NOT_STUDYABLE;
  }

  public canGoToNotLegalApprovedInform(study: any) {
    return (
      study.isApproved === null &&
      (study.status === STATUSES.FINALIZED ||
        study.status === STATUSES.PRE_STUDIED)
    );
  }

  public canGoToSentToInformView(study: any) {
    return study.status === STATUSES.PRE_STUDIED && study.isApproved === true;
  }

  // obtener lista de estudios iniciados (pre-estudios) por el usuario
  public async obtenerMisEstudiosEjecutivo(
    pageUrl = "",
    searchText?: string,
    searchLabel: string = "",
    ordering = ""
  ): Promise<any> {
    const params = {
      ...(searchText && { [searchLabel]: searchText }),
      ...(ordering && { ordering }),
    };

    const response: ResponseList = await this.estudioGetList(
      "",
      pageUrl,
      params
    );

    response.results = response.results.map((study: any) => {
      const statusDisplayName = this.getStatusDisplayName(study) || "- -";
      const hasCertificate = this.canGoToResult(study);
      const studyInfo = {
        ...study,
        statusDisplayName,
        hasCertificate,
      };
      return studyInfo;
    });

    return response;
  }
}
