import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  // styleUrls: ['./boton.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() valid: boolean;
  @Input() loading: boolean;
  @Input() text: string;
  @Output() onClick = new EventEmitter();

  preloading = false;

  constructor() {}

  ngOnInit() {}

  handleClick(event) {
    this.preloading = true;
    event.preventDefault();
    event.stopPropagation();
    this.onClick.emit();
    this.preloading = false;
  }
}
