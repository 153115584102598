import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError } from "rxjs/operators";
import { throwError, Observable, BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class TokenService {
  urlVerify: string = environment.api_gateways.verifyToken;
  urlRefresh: string = environment.api_gateways.refreshToken;
  constructor(private http: HttpClient) {}
  private setInfoWhenSessionExpired = new BehaviorSubject<any>({});
  setInfoWhenSessionExpired$ = this.setInfoWhenSessionExpired.asObservable();

  public getToken(): string {
    return localStorage.getItem("token");
  }
  public getRefreshToken(): string {
    return localStorage.getItem("refresh");
  }

  public verify(token: string): Observable<any> {
    return this.http
      .post(this.urlVerify, {
        token,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }
  public refresh(refresh: string): Observable<any> {
    return this.http
      .post(this.urlRefresh, {
        refresh,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }
}
