import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LogsService } from "../services/logs.service";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
})
export class ErrorComponent implements OnInit {
  constructor(private logs: LogsService, private route: ActivatedRoute) {}

  ngOnInit() {
    const url = this.route.snapshot.url.map((o) => o.path).join("/");
    this.logs.enviarLog("visita", "error", url);
  }
}
