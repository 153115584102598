import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Subject } from "rxjs";
import { TokenService } from "./token.service";

interface Result {
  access: string;
  refresh: string;
  message: string;
}

interface LoginResult {
  access: string;
  refresh: string;
  message: string;
  customer: string;
}

interface Verify {
  code: string;
  detail: string;
}
@Injectable({
  providedIn: "root",
})
export class AuthSantanderService {
  // url del servicio estudio
  urlService: string = environment.api_gateways.authSantander;
  // mensaje de error del backend
  messageError =
    // tslint:disable-next-line: max-line-length
    "Estamos presentando problemas de conexión. Inténtalo nuevamente en unos segundos, si el problema persiste contáctanos a contacto@legalbot.cl";

  // estados
  private isLoggedIn: boolean;
  public token: string;
  public refresh: string;

  // subjects
  public loggedOut = new Subject<boolean>();

  constructor(private http: HttpClient, private tokenService: TokenService) {
    this.initAuth();
  }

  public loggedIn(): boolean {
    return this.isLoggedIn;
  }

  private initAuth(): void {
    const token = this.tokenService.getToken();
    const refresh = this.tokenService.getRefreshToken();
    this.token = token || "";
    this.refresh = refresh || "";
    if (this.token && this.token !== "") {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

  public async ingresar(username: string, password: string): Promise<Result> {
    // preparar cuerpo
    const body = {
      username,
      password,
    };

    try {
      const result: LoginResult = await this.http
        .post<LoginResult>(this.urlService, body)
        .toPromise();

      // guardar token del usuario si la respuesta es correcta
      if (result.access) {
        this.openSession(result.access, result.refresh);
      }
      return result;
    } catch {
      // error en llamado al endpoint
      const result: Result = {
        access: null,
        refresh: null,
        message: null,
      };
      return result;
    }
  }

  public exit() {
    this.closeSession();
    this.loggedOut.next(true);
  }

  public openSession(
    token: string,
    refreshToken: string,
    isSantander: boolean = false
  ): void {
    localStorage.setItem("token", token);
    localStorage.setItem("refresh", refreshToken);
    this.token = token;
    this.refresh = refreshToken;
    this.isLoggedIn = true;
  }

  private closeSession(): void {
    this.token = "";
    this.refresh = "";
    this.isLoggedIn = false;
    localStorage.clear();
  }
}
