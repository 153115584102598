<div appClickOutside id="modal-dialog">
  <div class="modal-header">
    <label class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </label>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="d-flex justify-content-center">
          <i class="fas fa-check-circle fa-3x"></i>
        </div>
      </div>
      <div class="col-md-9 mt-5 mb-2">
        <p class="text-justify">
          El informe de sociedad y poderes de la empresa RUT
          <b>{{ societyNationalId }}</b> fue asignado a:
        </p>
        <p class="text-center">
          <b>{{ lawyerName }} </b> con correo de contacto
          <b>{{ lawyerEmail }}</b>
        </p>
      </div>
      <div class="col-md-9 px-5 mx-5 mt-5 mb-4">
        <button
          class="btn btn-primary btn-block"
          (click)="close()"
          role="button"
          type="button"
        >
          Realizar una nueva consulta
        </button>
      </div>
    </div>
  </div>
</div>
