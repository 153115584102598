import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginSantanderComponent } from './login/login.component';
import { SearchComponent } from './search/search.component';
import {
  HistoryComponent,
  StudyTableComponent,
  StudyRowComponent,
} from './history/history.component';
import { ResultComponent } from './result/result.component';
import { ErrorComponent } from './error/error.component';
import { HeaderComponent } from './components/header/header.component';
import { IsAuthenticated } from './guard.module';
import { ButtonComponent } from './components/button/button.component';
import { ModalComponent } from './components/modal/modal.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { AlertComponent } from './components/alert/alert.component';
import { BrowserAlertComponent } from './components/browser-alert/browser-alert.component';
import { CertificateComponent } from './certificate/certificate.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';

@NgModule({
  declarations: [
    AppComponent,
    CertificateComponent,
    LoginSantanderComponent,
    SearchComponent,
    HistoryComponent,
    StudyTableComponent,
    StudyRowComponent,
    ResultComponent,
    ErrorComponent,
    HeaderComponent,
    ButtonComponent,
    ModalComponent,
    AlertComponent,
    BrowserAlertComponent,
    PdfViewerComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  providers: [
    IsAuthenticated,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
