<nav class="navbar navbar-expand-md navbar-light">
  <a class="navbar-brand">
    <img src="assets/images/legalbot-logo-h.png" />
  </a>

  <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngClass]="{ show: showNav }">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a
          class="nav-link"
          name="search"
          routerLinkActive="activo"
          routerLink="/busqueda"
        >
          Buscar
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          name="history"
          routerLinkActive="activo"
          routerLink="/historial"
        >
          Historial
        </a>
      </li>
    </ul>
    <div class="m-0 p-0">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" name="logout" (click)="exit()">Salir</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="alert-container">
    <div>
      <app-browser-alert></app-browser-alert>
    </div>
  </div>
</nav>
