import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { StudiesService } from "../services/studies.service";
import { LogsService } from "../services/logs.service";
import { ResultService } from "../services/result.service";
import { TokenService } from "../services/token.service";

@Component({
  selector: "app-busqueda",
  templateUrl: "./search.component.html",
})
export class SearchComponent implements OnInit {
  form = new FormGroup({
    rut: new FormControl("", Validators.required),
    risk: new FormControl(false),
    costCenter: new FormControl(null, Validators.required),
  });
  isSantander = false;

  loading = false;
  message: string;
  costCenters: any[];
  defaultCostCenter: string = null;
  hasCbr = false;

  constructor(
    private tokenService: TokenService,
    private studies: StudiesService,
    private logs: LogsService,
    private router: Router,
    private resultService: ResultService
  ) {}

  ngOnInit() {
    this.logs.enviarLog("visita", "busqueda");
    this.isSantander =
      localStorage.getItem("customerName")?.toLowerCase() === "santander";
    this.costCenters = (JSON.parse(localStorage.getItem("costCenters")) || []).sort(
      (a, b) => a.index - b.index
    );
    this.defaultCostCenter = JSON.parse(
      localStorage.getItem("defaultCostCenter")
    );
    this.hasCbr = JSON.parse(localStorage.getItem("hasCbr"));
    this.form.patchValue({ costCenter: this.defaultCostCenter });
    this.tokenService.verify(this.tokenService.getToken()).subscribe();
  }

  async search() {
    // limpiar RUT
    const nationalId = String(this.form.value.rut)
      .toUpperCase()
      .replace(/[\.\-\s]/gi, "");

    // Enviar log
    this.logs.enviarLog("boton", "buscar rut", nationalId);

    const re = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9kK]/i;
    if (!re.test(String(nationalId))) {
      this.message = `El RUT ingresado no es del formato de una empresa en Chile.`;
      return;
    }
    if (!this.dvIsValid(nationalId)) {
      this.message = `El RUT ingresado no es válido.`;
      return;
    }

    // comenzar estudio
    this.message = null;
    this.loading = true;

    const response = await this.studies.estudioComenzar(
      nationalId,
      this.form.value.costCenter
    );

    this.loading = false;

    if (response.ok === false) {
      this.message = response.mensaje;
      return;
    }

    // TODO: manejar error en base al codigo http. EN el servicio tambien
    // Arreglar interface (linea 127)
    if (response.hasOwnProperty("active")) {
      this.resultService.setResult(response);
      this.router.navigate(["/resultado"]);
    } else {
      this.resultService.setResult({});
      this.router.navigate(["/resultado", response.id]);
    }
  }

  dvIsValid(formatedRut: string): boolean {
    const cleanRut = formatedRut.replace(/\./g, "").replace(/-/g, "");
    const rut = cleanRut.substring(0, cleanRut.length - 1);
    const dv = cleanRut.substring(cleanRut.length - 1);

    let m = 0;
    let s = 1;
    for (let t = Number(rut); t; t = Math.floor(t / 10)) {
      s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
    }

    return s - 1 === Number(dv) || (s === 0 && dv.toLowerCase() === "k");
  }
}
