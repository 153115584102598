<div class="header-container">
  <app-header></app-header>
</div>
<div class="resultado-estudio">
  <div class="contenedor-resultado large">
    <i
      class="fa fa-cog a-spin cargando-inicio"
      *ngIf="loading; else loaded"
    ></i>

    <ng-template #loaded>
      <div class="aprobado">
        <app-pdf-viewer [pdf]="certificate"></app-pdf-viewer>
        <div class="button-container">
          <button
            class="btn btn-primary btn-block"
            role="button"
            type="button"
            (click)="print()"
          >
            Imprimir
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
