import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginSantanderComponent } from './login/login.component';
import { SearchComponent } from './search/search.component';
import { ResultComponent } from './result/result.component';
import { ErrorComponent } from './error/error.component';
import { IsAuthenticated } from './guard.module';
import { HistoryComponent } from './history/history.component';
import { CertificateComponent } from './certificate/certificate.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/busqueda',
    pathMatch: 'full',
  },
  {
    path: 'certificado/:id',
    canActivate: [IsAuthenticated],
    component: CertificateComponent,
  },
  {
    path: 'ingreso',
    component: LoginSantanderComponent,
  },
  {
    path: 'busqueda',
    canActivate: [IsAuthenticated],
    component: SearchComponent,
  },
  {
    path: 'historial',
    canActivate: [IsAuthenticated],
    component: HistoryComponent,
  },
  {
    path: 'resultado/:id',
    canActivate: [IsAuthenticated],
    component: ResultComponent,
  },
  {
    path: 'resultado',
    canActivate: [IsAuthenticated],
    component: ResultComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
