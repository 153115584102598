import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthSantanderService } from "./services/auth-santander.service";

@Injectable()
export class IsAuthenticated {
  constructor(private auth: AuthSantanderService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.loggedIn()) {
      return true;
    }
    this.router.navigate(["/ingreso"]);
  }
}
