import { Component, OnInit, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { StudiesService } from "../services/studies.service";

@Component({
  selector: "app-certificate",
  templateUrl: "./certificate.component.html",
  styleUrls: ["./certificate.component.scss"],
})
export class CertificateComponent implements OnInit {
  id: string;
  loading = true;
  certificate: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private studies: StudiesService
  ) {
    // tslint:disable-next-line: no-string-literal
    this.id = this.route.snapshot.params["id"];
  }

  ngOnInit() {
    this.getCertificate().then(() => (this.loading = false));
  }

  async getCertificate() {
    this.loading = true;
    const certificate = await this.studies.getCertificate(this.id);
    if (certificate === null) {
      this.router.navigate([""]);
      return;
    }
    this.certificate = certificate.document;
  }

  getPdfRoute() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:application/pdf;base64,${this.certificate}`
    );
  }

  async print() {
    const base64 = await fetch(
      `data:application/pdf;base64,${this.certificate}`
    );
    const pdf = await base64.blob();
    const blobUrl = URL.createObjectURL(pdf);
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)
    );
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }
}
