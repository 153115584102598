import { Component, OnInit, Input } from "@angular/core";

export enum AlertType {
  DANGER = "alert alert-danger",
  SUCCESS = "alert alert-success",
  WARNING = "alert alert-warning",
}

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
  constructor() {}
  @Input() alertType: AlertType;
  @Input() alertMessage = "";
  @Input() showAlert = false;

  ngOnInit() {}
}
