import {
  Component,
  ElementRef,
  OnInit,
  SecurityContext,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";

import { StudiesService } from "../services/studies.service";
import { LogsService } from "../services/logs.service";
import { ModalService } from "../services/modal.service";
import { ResultService } from "../services/result.service";
import { IResultServiceResponse } from "../models/Result";
import { AlertType } from "../components/alert/alert.component";

@Component({
  selector: "app-resultado",
  templateUrl: "./result.component.html",
})
export class ResultComponent implements OnInit {
  ALERT = AlertType;
  loading = true;
  id: string;
  hasApprovalCertificate = false;
  active = false;
  isSantander: boolean;

  hasDispatch: boolean;
  sendingToInform = false;
  errorMessage: string;
  checkRequestStudy = false;
  lawyerName = "";
  lawyerEmail = "";
  resultServiceResponse: Partial<IResultServiceResponse>;
  isRepeatedRut = false;
  dispatching = false;
  isRejected = false;
  isNotStudyable = false;
  isNotLegalApproved = false;
  canGoToSentToInformView = false;
  dispatchErrorMessage: string;

  study = null;

  constructor(
    private studies: StudiesService,
    private logs: LogsService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private resultService: ResultService,
    private sanitizer: DomSanitizer
  ) {
    // tslint:disable-next-line: no-string-literal
    this.id = this.route.snapshot.params["id"];
  }
  @ViewChild("content")
  myModal: ElementRef;

  ngOnInit() {
    this.logs.enviarLog("visita", "resultado", this.id);
    this.resultService.result$.subscribe((result) => {
      if (result) {
        this.resultServiceResponse = result;
      }
    });
    const patt = new RegExp(/^[0-9]+$/g);
    if (this.id) {
      if (!patt.test(this.id)) {
        this.router.navigate([""]);
      }
    } else {
      if (!this.resultServiceResponse) {
        this.router.navigate([""]);
      }
    }
    this.obtenerEstudio().then(() => (this.loading = false));
  }

  handleCheckRequestStudy() {
    this.checkRequestStudy = !this.checkRequestStudy;
  }

  getPdfRoute() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:application/pdf;base64,${this.study.certificate}`
    );
  }

  async obtenerEstudio() {
    this.loading = true;
    // guardar opciones de informe
    const customerName = localStorage.getItem("customerName");
    this.hasDispatch = JSON.parse(localStorage.getItem("hasDispatch"));
    if (this.resultServiceResponse.hasOwnProperty("active")) {
      this.isRepeatedRut = true;
      this.loading = false;
      this.id = String(this.resultServiceResponse.id);
      this.study = this.resultServiceResponse;
      this.active = this.resultServiceResponse.active;
      this.hasApprovalCertificate = this.resultServiceResponse.isPreApproved;
    } else {
      const result = await this.studies.obtenerEstudioGuardado(this.id);

      // verificar llamada http
      if (result === null) {
        this.router.navigate([""]);
        return;
      }

      this.study = result;

      this.isRejected = this.studies.canGoToRejectedInform(result);
      this.isNotLegalApproved =
        this.studies.canGoToNotLegalApprovedInform(result);
      this.isNotStudyable = this.studies.canGoToNotStudyableInform(result);
      this.canGoToSentToInformView =
        this.studies.canGoToSentToInformView(result);
      this.isSantander = customerName
        ? customerName.toLowerCase() === "santander"
        : false;

      this.hasApprovalCertificate =
        this.studies.canGoToLegalApprovalInform(result);

      if (this.isNotLegalApproved) {
        const certificate = await this.studies.getCertificate(this.id);
        this.study.certificate = certificate.document;
      }

      if (this.hasApprovalCertificate && !this.canGoToSentToInformView)
        this.router.navigate(["certificado", this.id]);
      if (
        !this.hasDispatch &&
        !this.isRepeatedRut && // to check
        (this.isNotStudyable || this.isRejected || this.isNotLegalApproved)
      )
        this.router.navigate(["certificado", this.id]);
    }
  }

  async enviar() {
    this.logs.enviarLog("boton", "enviar a informar", this.id);

    if (this.sendingToInform) {
      return;
    }
    this.errorMessage = null;
    this.sendingToInform = true;
    try {
      const response = await this.studies.marcarParaEstudioManual(this.id);
      if (response.ok) {
        this.router.navigate(["certificado", this.id]);
      } else {
        this.errorMessage = response.mensaje;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.sendingToInform = false;
    }
  }

  async requestStudy(force: boolean = false) {
    if (this.dispatching) { return; }
    this.dispatching = true;
    this.handleCheckRequestStudy();
    try {
      const response = await this.studies.studyDispatch(this.id, force);

      if (response.ok) {
        const { lawyerEmail, lawyerName } = response;

        if (lawyerEmail) {
          this.lawyerEmail = lawyerEmail;
          this.lawyerName = lawyerName;
          this.modalService.open(this.myModal);
        }
      } else {
        this.dispatchErrorMessage = response.mensaje;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.dispatching = false;
    }
  }

  backToSearch() {
    this.router.navigate(["busqueda"]);
  }

  async print() {
    const base64 = await fetch(
      `data:application/pdf;base64,${this.study.certificate}`
    );
    const pdf = await base64.blob();
    const blobUrl = URL.createObjectURL(pdf);
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)
    );
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }
}
