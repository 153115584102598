import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthSantanderService } from './services/auth-santander.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private auth: AuthSantanderService,
    private router: Router,
  ) {
    this.auth.loggedOut.subscribe(() => this.router.navigate(['/ingreso']));
  }
}
