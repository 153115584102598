import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit {
  pdfSrc;

  constructor(private sanitizer: DomSanitizer) {}
  @Input() pdf: string;

  ngOnInit() {
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:application/pdf;base64,${this.pdf}`
    );
  }
}
