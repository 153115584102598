import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";

import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  constructor(private modalService: ModalService, private router: Router) {}
  @Input() lawyerName = "";
  @Input() lawyerEmail = "";
  @Input("nationalId") societyNationalId = "";
  @ViewChild("content") myModal: ElementRef;

  ngOnInit(): void {}

  close() {
    this.modalService.close();
  }

  open() {
    this.modalService.open(this.myModal);
  }
}
