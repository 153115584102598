import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthSantanderService } from "./auth-santander.service";
import { TokenService } from "./token.service";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthSantanderService,
    private tokenService: TokenService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> | Observable<any> {
    if (!req.url.includes("/login")) {
      req = this.addToken(req);
    }
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes("/login") &&
          error.status === 401
        ) {
          if (req.url.includes("/refresh")) {
            this.redirectToLogin();
            return null;
          }
          return this.handle401Error(req, next);
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const refreshToken = this.tokenService.getRefreshToken();
    if (refreshToken) {
      return this.tokenService.refresh(refreshToken).pipe(
        switchMap((result: any) => {
          if (result.access) {
            this.authService.openSession(result.access, refreshToken);
            localStorage.setItem("token", result.access);
            return next.handle(this.addToken(request));
          }
        })
      );
    }
  }
  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    const token: string = this.tokenService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return request;
    }
    return request;
  }
  private redirectToLogin() {
    this.authService.exit();
  }
}
