<div class="ingreso">
  <form [formGroup]="form" (ngSubmit)="ingresar()">
    <app-browser-alert></app-browser-alert>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        placeholder="Usuario"
        formControlName="usuario"
        required
        autofocus
      />

      <label for="password" class="sr-only">Clave</label>
      <input
        name="password"
        type="password"
        class="form-control"
        placeholder="Clave"
        formControlName="clave"
        required
      />
    </div>

    <div *ngIf="message" class="alert alert-danger">{{ message }}</div>

    <app-button
      [loading]="loading"
      [valid]="form.valid"
      [text]="'Entrar'"
      (onClick)="ingresar()"
    ></app-button>
  </form>
</div>
