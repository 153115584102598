import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  tap,
} from "rxjs/operators";

import { StudiesService, STATUSES } from "../services/studies.service";
import { LogsService } from "../services/logs.service";
import { AlertType } from "../components/alert/alert.component";

@Component({
  selector: "[study-row]",
  template: `
    <td class="fecha">
      {{ study.createdAt | date: "dd-MM-yyyy HH:mm" }}
      <div *ngIf="study.executive && open">
        Usuario: {{ study.executive.username }}
      </div>
      <div *ngIf="study.costCenter && open">
        Centro de Costos: {{ study.costCenter.name }}
      </div>
    </td>
    <td>{{ study.rut.split(".").join("").replace("-", "") }}</td>
    <td class="text-uppercase">{{ study.businessName }}</td>
    <td>
      <b [ngClass]="statusClass">{{ study.statusDisplayName }}</b>
      <div *ngIf="study.lastDispatch && open">
        Abogado {{ study.lastDispatch.lawFirmName }}
      </div>
      <div *ngIf="study.lastDispatch && open">
        {{ study.lastDispatch.lawFirmEmail }}
      </div>
    </td>
    <td class="center-text">
      <i
        *ngIf="study.hasCertificate"
        (click)="handleCertificateClick(study)"
        class="far fa-file clickable-row"
        title="Ver certificado"
      ></i>
      <div
        *ngIf="!study.hasCertificate && study.status === statuses.PRE_STUDIED"
      >
        Informe no solicitado
      </div>
    </td>
    <td class="center-text">
      <i
        *ngIf="canExpand && !open"
        (click)="handleOpenClick(study)"
        class="fas fa-chevron-down clickable-row"
        title="Ver certificado"
      ></i>
      <i
        *ngIf="canExpand && open"
        (click)="handleOpenClick(study)"
        class="fas fa-chevron-up clickable-row"
        title="Ver certificado"
      ></i>
    </td>
  `,
})
export class StudyRowComponent {
  @Input() study: any;
  open = false;
  statusClass = "";
  canExpand = false;
  statuses = STATUSES;

  constructor(private router: Router) {}

  ngOnInit() {
    this.setStatusClass();
    this.setCanExpand();
  }

  setStatusClass() {
    if (this.study.statusDisplayName.indexOf("Aprobación") === 0)
      return (this.statusClass = "text-success");
    if (this.study.statusDisplayName.includes("Rechazo"))
      return (this.statusClass = "text-danger");
  }

  setCanExpand() {
    if (
      this.study.lastDispatch ||
      ("executive" in this.study && "costCenter" in this.study)
    )
      this.canExpand = true;
  }

  handleOpenClick(study: any) {
    this.open = !this.open;
  }

  async handleCertificateClick(study: any) {
    if (study.hasCertificate) {
      this.router.navigate(["/certificado", study.id]);
    }
  }
}

@Component({
  selector: "study-table",
  template: `
    <table class="table">
      <colgroup>
        <col style="width:9rem;" />
        <col style="width:4rem;" />
        <col />
        <col style="width:15rem;" />
        <col style="width:1rem;" />
        <col style="width:1rem;" />
      </colgroup>
      <thead>
        <tr>
          <th scope="col">Fecha</th>
          <th scope="col">RUT</th>
          <th scope="col">Razón Social</th>
          <th scope="col">Estado</th>
          <th scope="col" class="center-text">Certificado</th>
          <th scope="col" class="center-text"></th>
        </tr>
      </thead>
      <tbody>
        <tr study-row *ngFor="let study of studies" [study]="study"></tr>
      </tbody>
    </table>
  `,
})
export class StudyTableComponent {
  @Input() studies: any;
  constructor() {}
}

@Component({
  selector: "app-historial",
  templateUrl: "./history.component.html",
})
export class HistoryComponent implements OnInit {
  ALERT = AlertType;
  myStudies: any[];
  filteredStudies: Array<any>;
  loading: boolean;
  message: string;

  // studies and pagination
  previous: string;
  next: string;
  count: number;

  // busqueda por RUT
  rutSearch = "";
  searchRut$ = new Subject<string>();

  isSupervisor = false;

  constructor(private studies: StudiesService, private logs: LogsService) {}

  async ngOnInit() {
    this.logs.enviarLog("visita", "historial");
    this.loadStudies();
    this.searchRut$
      .pipe(
        debounceTime(500),
        filter((text) => !text || text.length > 2),
        distinctUntilChanged(),
        tap(() => this.reloadStudies())
      )
      .subscribe();
  }

  isUserSupervisor() {
    if (!this.myStudies.length) return;

    const study = this.myStudies[0];
    if ("cost_center" in study && "executive" in study) return true;
  }

  async loadStudies() {
    if (this.loading) {
      return;
    }

    this.message = null;
    this.loading = true;
    const data = await this.studies.obtenerMisEstudiosEjecutivo(
      "",
      this.rutSearch,
      "rut",
      "-created_at"
    );
    this.myStudies = data.results;
    this.count = data.count;
    this.previous = data.previous;
    this.next = data.next;
    this.loading = false;

    if (this.myStudies === null) {
      this.message = "Error al cargar tu historial";
    }
    this.isUserSupervisor();
  }

  formatRut(rut: string) {
    let newRut = rut.replace(/\./g, "").replace(/-/g, "");
    if (newRut.length > 2) {
      newRut = `${newRut.slice(0, 2)}.${newRut.slice(2)}`;
    }
    if (newRut.length > 5) {
      newRut = `${newRut.slice(0, 6)}.${newRut.slice(6)}`;
    }
    if (newRut.length > 9) {
      newRut = `${newRut.slice(0, 10)}-${newRut.slice(10)}`;
    }
    return newRut;
  }

  async goToPage(pageUrl: string) {
    if (this.loading) {
      return;
    }
    this.message = null;
    this.loading = true;
    const data = await this.studies.obtenerMisEstudiosEjecutivo(
      pageUrl,
      this.rutSearch,
      "rut"
    );
    this.myStudies = data.results.data.estudios;
    this.count = data.count;
    this.previous = data.previous;
    this.next = data.next;
    this.loading = false;

    if (this.myStudies === null) {
      this.message = "Error al cargar tu historial";
    }
  }
  previousStudies() {
    this.goToPage(this.previous);
  }

  nextStudies() {
    this.goToPage(this.next);
  }

  reloadStudies() {
    this.logs.enviarLog("boton", "recargar historial");
    this.loadStudies();
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  filterByRUT(rut: string): void {
    const formatedRut = this.formatRut(rut);
    this.rutSearch = formatedRut;
    this.searchRut$.next(formatedRut);
  }
}
