import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IResultServiceResponse } from "../models/Result";

@Injectable({
  providedIn: "root",
})
export class ResultService {
  constructor() {}

  private result = new BehaviorSubject<Partial<IResultServiceResponse>>({});
  result$ = this.result.asObservable();

  setResult(result: any) {
    this.result.next(result);
  }
}
