import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ICustomerData, ICostCenter } from "../models/Login";

@Injectable({
  providedIn: "root",
})
export class CustomerDataService {
  urlService: string = environment.api_gateways.customerData;
  hasDispatch = false;
  // mensaje de error del backend
  messageError = `Estamos presentando problemas de conexión. Inténtalo 
    nuevamente en unos segundos, si el problema persiste contáctanos a 
    contacto@legalbot.cl`;

  constructor(private http: HttpClient) {}
  public async getCustomerData(): Promise<ICustomerData> {
    try {
      const token = localStorage.getItem("token");
      const result: ICustomerData = await this.http
        .get<ICustomerData>(this.urlService, {
          headers: new HttpHeaders({ Authorization: "Bearer " + token }),
        })
        .toPromise();
      // guardar token del usuario si la respuesta es correcta
      if (result.name) {
        this.storeCustomerData(
          result.hasDispatch,
          result.name,
          result.formalName,
          result.costCenters,
          result.defaultCostCenter.id,
          result.hasCbr
        );
      }
      return result;
    } catch {
      // error en llamado al endpoint
      const result: ICustomerData = {
        name: null,
        formalName: null,
        hasCbr: false,
        hasGroupsApiData: null,
        hasProductsApiData: null,
        hasFacultyApiData: null,
        hasStructureApiData: null,
        createdAt: "",
        hasDispatch: false,
        updatedAt: "",
        id: "",
        costCenters: null,
        defaultCostCenter: null,
      };
      return result;
    }
  }

  private storeCustomerData(
    hasDispatch: boolean,
    customerName: string,
    formalCustomerName: string,
    costCenters: ICostCenter[],
    defaultCostCenter: number,
    hasCbr = false
  ): void {
    localStorage.setItem("costCenters", JSON.stringify(costCenters));
    localStorage.setItem(
      "defaultCostCenter",
      JSON.stringify(defaultCostCenter)
    );
    localStorage.setItem("hasCbr", JSON.stringify(hasCbr));
    localStorage.setItem("hasDispatch", JSON.stringify(hasDispatch));
    localStorage.setItem("customerName", customerName);
    localStorage.setItem("formalCustomerName", formalCustomerName);
    this.hasDispatch = hasDispatch;
  }
}
