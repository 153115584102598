import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LogsService {
  // url del servicio estudio
  urlService = "";

  constructor(
    private http: HttpClient // private auth: AuthService,
  ) {}

  // función general de llamado get al servicio de estudios
  public async enviarLog(
    accion: string,
    accionado: string,
    objeto: string = "- -"
  ) {
    // TODO: crear logs en backend nuevo
    return false;
  }
}
