import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthSantanderService } from "../services/auth-santander.service";
import { CustomerDataService } from "../services/customer-data.service";

@Component({
  selector: "app-ingreso-santander",
  templateUrl: "./login.component.html",
})
export class LoginSantanderComponent implements OnInit {
  form = new FormGroup({
    usuario: new FormControl(""),
    clave: new FormControl(""),
  });

  loading = false;
  message: string;

  showPasswordChange = false;
  trueUser: string;

  constructor(
    private authSantander: AuthSantanderService,
    private customerData: CustomerDataService,
    private router: Router
  ) {
    if (this.authSantander.loggedIn()) {
      this.router.navigate([""]);
    }
  }

  ngOnInit() {}

  async ingresar() {
    if (this.loading) {
      return;
    }

    // limpiar inputs
    const user = this.form.value.usuario.trim();
    const password = this.form.value.clave.trim();

    // ingresar
    this.message = null;
    this.loading = true;
    const login = await this.authSantander.ingresar(user, password);
    this.loading = false;
    if (!login.access && login.access !== "") {
      if (login.message) {
        this.message = login.message;
      } else {
        this.message = `Credenciales incorrectas`;
      }
      return;
    }
    await this.customerData.getCustomerData();
    this.router.navigate([""]);
  }
}
