<app-header></app-header>
<div class="historial-container">
  <div class="historial">
    <app-alert [alertType]="ALERT.DANGER" [alertMessage]="message"> </app-alert>
    <div class="busqueda-rut">
      <input
        name="busqueda-rut"
        (keyup)="filterByRUT(getValue($event))"
        placeholder="Buscar por RUT"
        [value]="rutSearch"
      />
    </div>

    <div class="actualizar" (click)="reloadStudies()">
      <i class="fas fa-sync" [ngClass]="{ 'a-spin': loading }"></i>
    </div>
    <div class="header-table">
      <div class="left">
        <p *ngIf="myStudies">
          {{ myStudies.length }} de {{ count }} estudios seleccionados
        </p>
      </div>

      <div>
        <button
          *ngIf="previous"
          type="button"
          class="btn-page"
          (click)="previousStudies()"
        >
          Anterior
          <i class="fas fa-arrow-left"></i>
        </button>
        <button
          *ngIf="next"
          type="button"
          class="btn-page"
          (click)="nextStudies()"
        >
          <i class="fas fa-arrow-right"></i>
          Siguiente
        </button>
      </div>
    </div>
    <study-table [studies]="myStudies"></study-table>
  </div>
</div>
