<app-header></app-header>
<div class="busqueda-principal">
  <div class="busqueda-container">
    <p *ngIf="isSantander" class="text-danger">
      <b>
        RECUERDA: Antes de solicitar el Informe de Poderes, es
        <u>REQUISITO</u> contar previamente con la Aprobación de Riesgo
      </b>
    </p>

    <p *ngIf="hasCbr">
      Ingresa el Rut de una empresa inscrita en el sistema de Empresas en un Día
      o en el Conservador de Bienes Raíces. El procesamiento de tu búsqueda
      puede durar hasta 60 segundos.
    </p>
    <p *ngIf="!hasCbr">
      Ingresa el Rut de una empresa inscrita en el sistema de Empresas en un
      Día. El procesamiento de tu búsqueda puede durar hasta 60 segundos.
    </p>

    <form [formGroup]="form" (ngSubmit)="search()">
      <div class="form-group">
        <div>
          <input
            type="text"
            class="form-control"
            placeholder="RUT Empresa"
            formControlName="rut"
            required
          />
        </div>
        <div *ngIf="costCenters.length > 1" class="form-group checkbox-group">
          <div *ngFor="let costCenter of costCenters">
            <input
              id="{{ costCenter.name }}"
              type="radio"
              class="product-checkbox-input"
              formControlName="costCenter"
              [value]="costCenter.id"
            />
            <label
              class="product-checkbox-label checkbox-label"
              for="{{ costCenter.name }}"
            >
              {{ costCenter.label }}
            </label>
          </div>
        </div>

        <div class="form-group risk-checkbox-container">
          <div *ngIf="isSantander" class="risk-checkbox checkbox-label">
            <input
              id="risk"
              type="checkbox"
              class="risk-checkbox-input"
              formControlName="risk"
              required
            />
            <label class="risk-checkbox-label checkbox-label" for="risk">
              Declaro contar con la aprobación de riesgo
            </label>
          </div>
        </div>
      </div>

      <br />
      <div *ngIf="message" class="alert alert-danger">{{ message }}</div>

      <app-button
        [loading]="loading"
        [valid]="form.valid && !loading"
        [text]="'Buscar'"
        (onClick)="search()"
      ></app-button>
    </form>
  </div>
</div>
